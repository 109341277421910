import React, { useEffect } from 'react'

import { hooks, I18n } from '@front/volcanion'
import { Loader } from '@front/squirtle'
import { HookUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const [, { closeDialog }] = hooks.useDialogs()
  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [order_id] = hooks.useFormState('order_id')
  const [closeDialogOnComplete] = hooks.useFormState('closeDialogOnComplete')

  const [order, status] = hooks.useModel('order', [order_id], {
    single: true,
    populate: ['assigned_transport', 'loads']
  })

  const job_id = order?.job
  const assigned_transport = order?.assigned_transport
  const transport_status = assigned_transport?.status
  const [next_transport_status] = hooks.useJobNextStatus({ job_id })
  const [, { display_config, disabled_config, required_config }] = hooks.usePaymentForm({ job_id })

  const nextButtonLabel = !!next_transport_status ? I18n.t(`transportStatus.${next_transport_status}.user`) : null
  const transportStatus = hooks.useTransportStatus(assigned_transport, { translated: true, load_type: 'user' })
  const transportStatusColor = hooks.useTransportStatusColor(assigned_transport)
  const orderStatus = hooks.useOrderStatus(order, { translated: true })
  const orderStatusColor = hooks.useOrderStatusColor(order)

  const isOrderCompleted = _.includes(['complete', 'canceled'], transport_status)
  const isPayment = transport_status === 'off_board'
  const configs = { display_config, disabled_config, required_config }

  useEffect(() => {
    if (!!closeDialogOnComplete && !!isOrderCompleted)
      closeDialog('simulator')
  }, [transport_status])

  const mergedProps = {
    configs,
    transportStatus,
    transportStatusColor,
    orderStatus,
    orderStatusColor,
    nextButtonLabel,
    isPayment,
    isSubmitting,
    requestSubmit,
    isOrderCompleted,
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([status])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
