import React from 'react'

import { hooks, I18n } from '@front/volcanion'
import { Loader, NumberField } from '@front/squirtle'

import { Grid, Icon, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

const SubmitButton = ({ onClick, isLoading, label, ...rest }) => (
  <LoadingButton
    onClick={onClick}
    loading={isLoading}
    fullWidth
    loadingIndicator={(
      <Loader size={30} isLoading={isLoading} />
    )}
    variant='contained'
    {...rest}
  >
    {label}
  </LoadingButton>
)

const PaymentRow = ({ name, label, configs }) => {
  const display = configs.display_config?.[name]
  const disabled = configs.disabled_config?.[name]
  const required = configs.required_config?.[name]

  return (
    <Grid item xs={12} hidden={!display}>
      <NumberField
        name={name}
        label={label}
        disabled={disabled}
        required={!disabled && !!required}
        type='float'
        InputProps={{ endAdornment: <Icon color='primary'> {'euro'} </Icon> }}
      />
    </Grid>
  )
}

const PaymentSection = ({ configs }) => (
  <Grid container item xs={12} spacing={2}>
    <PaymentRow
      name='base'
      label={I18n.t('quotation.amount')}
      configs={configs}
    />
    <PaymentRow
      name='duration'
      label={I18n.t('quotation.duration')}
      configs={configs}
    />
    <PaymentRow
      name="driver_package_fee"
      label={I18n.t('quotation.driver_package_fee')}
      configs={configs}
    />
    <PaymentRow
      name='fees'
      label={I18n.t('quotation.fees')}
      configs={configs}
    />
    <PaymentRow
      name="prepaid"
      label={I18n.t('quotation.prepaid')}
      configs={configs}
    />
    <PaymentRow
      name="handling_duration"
      label={I18n.t('quotation.handling_duration')}
      configs={configs}
    />
    <PaymentRow
      name="option_amount"
      label={I18n.t('quotation.option_amount')}
      configs={configs}
    />
  </Grid>
)

const SimulatorDialog = ({
  transportStatus,
  transportStatusColor,
  orderStatus,
  orderStatusColor,
  nextButtonLabel,
  isPayment,
  isSubmitting,
  requestSubmit,
  isOrderCompleted,
  configs,
}) => {
  return (
    <Grid container xs spacing={2}>
      <Grid container item xs={12} direction='row' justifyContent='flex-start'>
        <Typography variant={'h5'} color={orderStatusColor} fontWeight={400}>{orderStatus}</Typography>
        <Typography variant={'h5'} marginX={1}>|</Typography>
        <Typography variant={'h5'} color={transportStatusColor} fontWeight={400}>{transportStatus}</Typography>
      </Grid>
      {!isOrderCompleted && isPayment && <PaymentSection configs={configs} />}
      {!isOrderCompleted &&
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <SubmitButton
              isLoading={isSubmitting}
              onClick={() => requestSubmit(isPayment ? 'payment' : 'next')}
              label={nextButtonLabel}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              isLoading={isSubmitting}
              onClick={() => requestSubmit('cancel')}
              label={I18n.t('transport.status.not_loaded')}
            />
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

export default React.memo(SimulatorDialog)
