// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
  width: 50px;
  transition: all 0.1s ease-out;
  margin-bottom: 50px;
}
.drawer .header {
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-out;
}
.drawer .header.open {
  transition: all 0.1s ease-in;
  justify-content: flex-start;
}
.drawer.open {
  width: 240px;
  transition: all 0.1s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/elements/sidebar/styles.scss","webpack://./variables.scss"],"names":[],"mappings":"AAEA;EACE,WCDoB;EDEpB,6BAAA;EACA,mBCLc;ADIhB;AAGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;AADJ;AAGI;EACE,4BAAA;EACA,2BAAA;AADN;AAKE;EACE,YClBiB;EDmBjB,4BAAA;AAHJ","sourcesContent":["@use \"../../../variables.scss\";\r\n\r\n.drawer {\r\n  width: variables.$sidebar-width-close;\r\n  transition: all variables.$transition-time ease-out;\r\n  margin-bottom: variables.$topbar-height;\r\n\r\n  .header {\r\n    height: variables.$topbar-height - 1;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    transition: all variables.$transition-time ease-out;\r\n\r\n    &.open {\r\n      transition: all variables.$transition-time ease-in;\r\n      justify-content: flex-start;\r\n    }\r\n  }\r\n\r\n  &.open {\r\n    width: variables.$sidebar-width-open;\r\n    transition: all variables.$transition-time ease-in;\r\n  }\r\n}","$topbar-height: 50px;\n$safearea-padding: 10px;\n$sidebar-width-close: 50px;\n$sidebar-width-open: 240px;\n$transition-time: 0.1s;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
