// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appbar {
  height: 50px;
  align-items: flex-start;
  justify-content: center;
}
.appbar .toolbar {
  padding: 0px;
  width: calc(100% - 50px);
  margin-left: 50px;
  padding-right: 10px;
  padding-left: 10px;
  transition: width 0.1s ease-out, margin 0.1s ease-out;
  height: 50px;
  min-height: 50px;
}
.appbar .toolbar.shift {
  width: calc(100% - 240px - 45px);
  margin-left: 240px;
  transition: width 0.1s ease-in, margin 0.1s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/elements/topbar/styles.scss","webpack://./variables.scss"],"names":[],"mappings":"AACA;EACE,YCFc;EDGd,uBAAA;EACA,uBAAA;AAAF;AACE;EACE,YAAA;EACA,wBAAA;EACA,iBCNkB;EDOlB,mBCRe;EDSf,kBCTe;EDUf,qDAAA;EACA,YCZY;EDaZ,gBCbY;ADchB;AAAI;EACE,gCAAA;EACA,kBAAA;EACA,mDAAA;AAEN","sourcesContent":["@use \"../../../variables.scss\";\r\n.appbar {\r\n  height: variables.$topbar-height;\r\n  align-items: flex-start;\r\n  justify-content: center;\r\n  .toolbar {\r\n    padding: 0px;\r\n    width: calc(100% - variables.$sidebar-width-close);\r\n    margin-left: variables.$sidebar-width-close;\r\n    padding-right: variables.$safearea-padding;\r\n    padding-left: variables.$safearea-padding;\r\n    transition: width variables.$transition-time ease-out, margin variables.$transition-time ease-out;\r\n    height: variables.$topbar-height;\r\n    min-height: variables.$topbar-height;\r\n    &.shift {\r\n      width: calc(100% - variables.$sidebar-width-open - 45px);\r\n      margin-left: calc(variables.$sidebar-width-open);\r\n      transition: width variables.$transition-time ease-in, margin variables.$transition-time ease-in;\r\n    }\r\n  }\r\n}\r\n","$topbar-height: 50px;\n$safearea-padding: 10px;\n$sidebar-width-close: 50px;\n$sidebar-width-open: 240px;\n$transition-time: 0.1s;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
